<template>
  <base-view id="alegeus-training">
    <view-header title="Alegeus Training" image="bg-login.jpg">
      Alegeus is the platform that employers use to access and edit information
      about their employees’ flexible health benefits. This platform allows
      manipulation of benefits cards, adding dependents, reimbursement methods,
      and more.
    </view-header>
    <view-subnav-section routed :links="links" />
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewHeader from "@/components/layout/ViewHeader.vue";
import ViewSubnavSection from "@/components/layout/ViewSubnavSection.vue";

export default {
  data() {
    return {
      links: ["Alegeus Training 1", "Alegeus Training 2", "Flex Templates"]
    };
  },
  components: {
    BaseView,
    ViewHeader,
    ViewSubnavSection
  }
};
</script>

<style></style>
