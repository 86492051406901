<template>
  <div class="w-auto h-full mx-4 my-0 lg:max-w-6xl xl:mx-auto">
    <div
      class="flex flex-col justify-start w-auto h-full pt-16 pb-24 my-0 lg:flex-row"
    >
      <div class="flex flex-row">
        <div class="w-full text-left lg:w-56 nav first:mt-0">
          <h3 class="mb-2 text-xl font-semibold">{{ title }}</h3>
          <div class="flex flex-row mb-6 overflow-auto lg:flex-col">
            <template v-if="routed">
              <router-link
                v-for="(route, index) in childRoutes"
                :key="index"
                :to="route.path"
                class="block p-2 text-base transition-colors border-b rounded-t lg:border-b-0 whitespace-nowrap lg:rounded-b hover:bg-neutral-light active:bg-neutral"
              >
                {{ links[index] }}
              </router-link>
              <router-link
                v-if="title == 'Employer Resources'"
                :to="`/support/categories/360002944833`"
                class="block p-2 text-base transition-colors border-b rounded-t lg:border-b-0 whitespace-nowrap lg:rounded-b hover:bg-neutral-light active:bg-neutral"
                >Employer Support and FAQ</router-link
              >
            </template>
            <template v-else>
              <div
                v-for="(child, index) in children"
                :key="index"
                @click="activeChild = child"
                class="block p-2 transition-colors border-b rounded-t lg:border-b-0 whitespace-nowrap lg:rounded-b hover:bg-neutral-light active:bg-neutral"
                :class="{
                  'text-primary-light bg-neutral-light br-primary-light border-b-2 border-primary-light lg:border-r-2 lg:border-b-0':
                    activeChild == child
                }"
              >
                {{ child.name }}
              </div>
            </template>
          </div>
        </div>
        <div class="hidden mx-4 border-r lg:block" />
      </div>

      <router-view v-if="routed" class="flex-1 h-full mx-auto align-top" />
      <component v-else :is="activeChild" class="flex-1" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    routed: Boolean,
    links: Array,
    children: Array,
    startIndex: { type: Number, default: 0 }
  },
  data() {
    return {
      parentPath: "",
      childRoutes: [],
      activeChild: this.children ? this.children[this.startIndex] : null
    };
  },
  created() {
    if (this.routed) {
      var fullPath = this.$route.path;
      var endIndex = fullPath.lastIndexOf("/");
      this.parentPath =
        endIndex !== -1
          ? fullPath.substr(0, endIndex)
          : fullPath.substr(0, fullPath.length);
      this.getChildRoutes();
    } else {
      this.children.forEach(element => {
        if (element.data == null) {
          element.name = this.getComponentName(element.__file);
        } else {
          element.name = element.data().componentName;
        }
      });
    }
  },
  methods: {
    getComponentName(str) {
      return str.substring(str.lastIndexOf("/") + 1, str.lastIndexOf("."));
    },
    getChildRoutes() {
      this.childRoutes = this.$router.options.routes.find(
        route => route.path === this.parentPath
      ).children;
    }
  }
};
</script>

<style scoped>
a.router-link-active {
  color: theme("colors.primary.light");
  background-color: theme("colors.neutral.light");
  border-bottom: 0.25rem solid theme("colors.primary.light");
  border-right: none;
}

@media only screen and (min-width: 1024px) {
  a.router-link-active {
    border-right: 0.25rem solid theme("colors.primary.light");
    border-bottom: none;
  }
}
</style>
